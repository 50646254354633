<template>
  <div class="con" v-if="!$store.state.pcshow">
    <img src="../../assets/img/index/zhongxinGH1.png" alt="" />
    <div class="btngroup clearfix">
      <!-- <router-link to="/zhongkeYC" :class="$route.path === '/zhongkeYC'? 'active': ''">
        <div class="companyIntro">
          <p>中科医创</p>
        </div>
      </router-link> -->
      <router-link to="/zhongxinGH"  :class="$route.path === '/zhongxinGH'? 'active': ''">
        <div class="structure">中鑫广汇</div>
      </router-link>
      <router-link to="/yilianGl"  :class="$route.path === '/yilianGl'? 'active': ''">
        <div class="structure">亿联管理</div>
      </router-link>
      <router-link to="/chanyeYQ"  :class="$route.path === '/chanyeYQ'? 'active': ''">
        <div class="structure">产业园区</div>
      </router-link>
    </div>
    <div class="hprofile">
      <h3 class="conh3">COMPANY PROFILE</h3>
      <h4 class="conh4">公司简介</h4>
      <img src="../../assets/img/index/zhongxinGH2.png" alt="">
      <!-- <h5>中关村高新技术企业</h5> -->
      <p>中鑫广汇医疗科技是一家面向全国各级医院的医疗器械配送平台，致力于为研发、生产、流通、临床等机构搭 建信息交换和项目对接的医疗大数据平台，提供全国范围内第三方配送、产品分销渠道拓展、采购服务等产业 链服务项目。</p>
      <p>公司深入优化业务布局，致力于为研发、生产、流通、临床、等机构搭建信息交换和项目对接的医疗物流大数 据平台，提供全国范围内第三方配送、产品分销渠道拓展、采购服务等产业链服务项目并开拓国际市场，高效 促进医疗事业的发展。拥有医药，器械，国际贸易等经营销售全牌照。</p>
    </div>
    <div class="yj2">
      <h4><span></span>业务内容</h4>
      <div class="bus">
        <img src="../../assets/img/index/zhongxinGH6.png" alt="">
        <h3>医药行业平台全国配送 </h3>
        <p>配送领域专业精准，面向医疗设备、医疗耗材、检验试剂等医疗产品，提供全国范围内第三方配送。</p>
      </div>
      <div class="bus">
        <img src="../../assets/img/index/zhongxinGH3.png" alt="">
        <h3>城市合伙人经销模式 </h3>
        <p>自主投资运作，以外包的形式合作各区域的代理合伙人，且产品代理丰富。</p>
      </div>
      <div class="bus">
        <img src="../../assets/img/index/zhongxinGH4.png" alt="">
        <h3>全产业链精准布局</h3>
        <p>自有仓库仓储，以先进的营销理念面向全国各级医院，提供采购服务等全产业链服务项目。</p>
      </div>
    </div>
    <div class="yj3">
      <div class="bus2">
        <img src="../../assets/img/index/zhongxinGH5.png" alt="">
        <h3>自有产业实体 </h3>
        <p>口罩厂是疫情期间，应拱墅区政府要求所成立的。前期主要以医用口罩为主，现已经打造了符合国家医疗器械生产法律法规的设备和生产场地。</p>
        <p>厂址坐落在拱墅区的高科技产业园区，厂房总共有1500平方米，共计员工58人。</p>
      </div>
    </div>
    <div class="hpartner">
      <h3 class="conh3">cooperative partner</h3>
      <h4 class="conh4">合作伙伴</h4>
      <div class="scro">
        <div class="imggroup clearfix">
          <div class="li" v-for="(item,index) in imglist" :key="index"><img :src="item" alt=""></div>
        </div>
      </div>
    </div>
  </div>

  <!-- 中鑫广汇 -->
  <div class="main" v-else>
    <img src="../../assets/img/index/imgZXGH(1).png" alt="" />
    <div class="hatchTotalNav">
      <div class="hatchleft">
        <div class="hatchleftContent">
          <p>INCUBATION ENTERPRISE</p>
          <p>孵化企业</p>
        </div>
      </div>
      <div class="hatchNavRight">
        <div class="hatchNavRightContent">
          <!-- <router-link to="/zhongkeYC">
            <div>中科医创</div>
          </router-link> -->
          <router-link
            to="/zhongxinGH"
            :class="
              $route.path === '/zhongxinGH' ? 'companyActive' : 'companyDefault'
            "
          >
            <div>
              <p>中鑫广汇</p>
              <div class="bluebottom"></div>
            </div>
          </router-link>
          <router-link to="/yilianGl">
            <div>亿联体事业部</div>
          </router-link>
          <router-link to="/chanyeYQ">
            <div>
              <p>产业园区</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="contentMain">
      <!-- 公司简介 -->
      <div class="companyIntro">
        <div class="IntroLeft">
          <h4>COMPANY PROFILE</h4>
          <h4>公司简介</h4>
          <p>
            中鑫广汇医疗科技是一家面向全国各级医院的医疗器械配送平台，致力于为研发、生产、流通、临床等机构搭
            建信息交换和项目对接的医疗大数据平台，提供全国范围内第三方配送、产品分销渠道拓展、采购服务等产业
            链服务项目。
          </p>
          <p>
            公司深入优化业务布局，致力于为研发、生产、流通、临床、等机构搭建信息交换和项目对接的医疗物流大数
            据平台，提供全国范围内第三方配送、产品分销渠道拓展、采购服务等产业链服务项目并开拓国际市场，高效
            促进医疗事业的发展。拥有医药，器械，国际贸易等经营销售全牌照。
          </p>
        </div>
        <div class="introRight">
          <img src="../../assets/img/index/imgZXGH(3).png" alt="" />
        </div>
      </div>
      <!-- 业务内容标题 -->
      <div class="croBusiness">
        <p>业务内容</p>
        <div class="blueBttom"></div>
      </div>
      <!-- 业务内容 -->
      <div class="bussinessContent">
        <div class="img">
          <div class="defaultImg">
            <img src="../../assets/img/index/imgZXGH(3).png" alt="" />
          </div>
          <div class="activeImg">
            <img src="../../assets/img/index/imgZXGH(32).png" alt="" />
          </div>
          <div class="busyTitle">
            <h4>医药行业平台全国配送</h4>
          </div>
          <p>
            配送领域专业精准，面向医疗设备、医疗耗材、检验试剂等医疗产品，提供全国范围内第三方配送。
          </p>
        </div>
        <div class="img">
          <div class="defaultImg">
            <img src="../../assets/img/index/imgZXGH(4).png" alt="" />
          </div>
          <div class="activeImg">
            <img src="../../assets/img/index/imgZXGH(33).png" alt="" />
          </div>
          <div class="busyTitle">
            <h4>城市合伙人经销模式</h4>
          </div>
          <p>
            自主投资运作，以外包的形式合作各区域的代理合伙人，且产品代理 丰富。
          </p>
        </div>
        <div class="img">
          <div class="defaultImg">
            <img src="../../assets/img/index/imgZXGH(5).png" alt="" />
          </div>
          <div class="activeImg">
            <img src="../../assets/img/index/imgZXGH(34).png" alt="" />
          </div>
          <div class="busyTitle">
            <h4>全产业链精准布局</h4>
          </div>
          <p>
            自有仓库仓储，以先进的营销理念
            面向全国各级医院，提供采购服务等全产业链服务项目。
          </p>
        </div>
      </div>
      <!-- 自有产业实体 -->
      <div class="industryEntity">
        <div class="industryEntityContent">
          <div class="industryEntityLeft">
            <h4>自有产业实体</h4>
            <p>
              口罩厂是疫情期间，应拱墅区政府要求所成立的。前期主要以医用口罩为主，现已
              经打造了符合国家医疗器械生产法律法规的设备和生产场地。
            </p>
            <p>
              厂址坐落在拱墅区的高科技产业园区，厂房总共有1500平方米，共计员工58人。
            </p>
          </div>
          <div class="industryEntityRight">
            <img src="../../assets/img/index/imgZXGH(6).png" alt="" />
          </div>
        </div>
      </div>
      <!-- 合作伙伴 -->
      <div class="partner">
        <div class="partnerTop">
          <h4>cooperative partner</h4>
          <p>合作伙伴</p>
        </div>
        <div class="partnerBottom">
          <ul>
            <li>
              <img src="../../assets/img/index/imgZXGH(7).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(8).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(9).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(10).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(11).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(12).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(13).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(14).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(15).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(16).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(17).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(18).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(19).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(20).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(21).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(22).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(23).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(24).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(25).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(26).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(27).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(28).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(29).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(30).png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/index/imgZXGH(31).png" alt="" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      imglist:[
        require('../../assets/img/index/cooperation1.png'),
        require('../../assets/img/index/cooperation2.png'),
        require('../../assets/img/index/cooperation3.png'),
        require('../../assets/img/index/cooperation4.png'),
        require('../../assets/img/index/cooperation5.png'),
        require('../../assets/img/index/cooperation6.png'),
        require('../../assets/img/index/cooperation7.png'),
        require('../../assets/img/index/cooperation8.png'),
        require('../../assets/img/index/cooperation9.png'),
        require('../../assets/img/index/cooperation10.png'),
        require('../../assets/img/index/cooperation11.png'),
        require('../../assets/img/index/cooperation12.png'),
        require('../../assets/img/index/cooperation13.png'),
        require('../../assets/img/index/cooperation14.png'),
        require('../../assets/img/index/cooperation15.png'),
        require('../../assets/img/index/cooperation16.png'),
        require('../../assets/img/index/cooperation17.png'),
        require('../../assets/img/index/cooperation18.png'),
        require('../../assets/img/index/cooperation19.png'),
        require('../../assets/img/index/cooperation20.png'),
        require('../../assets/img/index/cooperation21.png'),
        require('../../assets/img/index/cooperation22.png'),
        require('../../assets/img/index/cooperation23.png'),
        require('../../assets/img/index/cooperation24.png'),
        require('../../assets/img/index/cooperation25.png'),
      ]
    }
  }
};
</script>

<style scoped lang="stylus">
  @media screen and (max-width: 960px) {
    .con{
      width: 100%;
      background: #F5F5F5;
      
      >img{
        display: block
        width:7.5rem;
        height:3rem;
      }

      .btngroup{
        width: 6.8rem;
        padding-left:0.35rem;
        padding-right:0.35rem; 
        a{
          display: block;
          width: 2rem;
          height: 0.68rem;
          background: #FFFFFF;
          float: left
          margin-top: 0.3rem;
          margin-right: 0.4rem;
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 0.68rem;
          text-align: center; 
        }
        a.active{
          background: #0085D0;
          color: #ffffff;
        }
        a:nth-child(3){
          margin-right:0;
        }
      }
      .hprofile{
        display block
        width calc( 100% - 0.36rem )
        padding-left 0.36rem
        background #F5F5F5
        padding-bottom 0.2rem

        img{
          display: block
          width: 5rem
          height: 3rem
          margin-bottom:0.24rem;
        }
        
        h5{
          font-size: 0.24rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #0085D0;
          padding-top: 0.24rem;
          padding-bottom:0.2rem;
        }
        p{
          width: 6.86rem;
          font-size: 0.24rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 0.45rem;
          margin-bottom:0.1rem;
          word-break:break-all;
        }
      }
      .yj2{
        // border-top: 0.32rem solid #f5f5f5;
        border-bottom:0.32rem solid #f5f5f5;
        display: block;
        width: calc( 100% - 0.35rem );
        background: #ffffff;
        padding-left:0.35rem;
        padding-bottom:0.05rem;

        >h4{
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          line-height: 0.39rem;
          padding-top:0.35rem;
          padding-bottom:0.32rem;
          span{
            display: inline-block;
            width: 0.03rem;
            height: 0.23rem;
            background: #0085D0;
            margin-right:0.07rem;
          }
        }
        .bus{
          width:6.8rem;
          img{
            display: block;
            width:6.8rem;
            height:3.5rem;
          }
          h3{
            font-size: 0.3rem;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            // line-height: 0.45rem;
            margin-top:0.32rem;
            margin-bottom:0.2rem;
          }
          p{
            font-size: 0.24rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 0.45rem;
            padding-bottom:0.2rem;
          }
        }
      }
      .yj3{
        // border-bottom:0.32rem solid #f5f5f5;
        display: block;
        width: 6.8rem;
        background: #ffffff;
        margin-left:0.35rem;
        margin-right:0.35rem;
        padding-bottom:0.05rem;
        border-radius: 0px 0px 0.1rem 0.1rem;

        .bus2{
          width:6.8rem;

          img{
            display: block;
            width:6.8rem;
            height:3.5rem;
          }
          h3{
            font-size: 0.3rem;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            // line-height: 0.45rem;
            margin-top:0.32rem;
            margin-bottom:0.2rem;
            padding-left:0.3rem;
          }
          p{
            padding-left:0.3rem;
            margin-right:0.3rem;
            font-size: 0.24rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 0.45rem;
            padding-bottom:0.2rem;
          }
        }
      }
      .hpartner{
        display block
        width calc( 100% - 0.36rem )
        padding-left 0.36rem
        position: relative
        overflow: hidden;
        
        .scro{
          overflow: scroll;

          .imggroup{
            width:33.93rem;
            padding-bottom:0.58rem;

            .li{
              display flex
              width: 2.16rem;
              height: 1.3rem;
              float: left 
              margin-right 0.45rem
              align-items: center;
              justify-content: center;
              // border: 1px solid #ccc;
              background: #fff;
              margin-bottom:0.16rem


              img{
                display block
                width: 2.16rem;
                height: 1.3rem;
                margin:0 auto;

              }

            }

            .li:nth-child(10),.li:nth-child(20){
              margin-right 0.35rem
            }
          }
        }
        .scro::-webkit-scrollbar {
          /*隐藏滚轮*/
          display: none;
        }
      }
    }
    .conh3{
      padding-top: 0.4rem
      font-size: 0.24rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    .conh4{
      font-size: 0.26rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      padding 0.15rem 0 0.24rem 0
    }
  }
  @media screen and (min-width: 960px) { 
    .main {
      font-family: Source Han Sans CN;

      >img {
        margin-top: 100px;
        width: 100%;
      }

      .hatchTotalNav {
        position: relative;
        display: flex;
        margin: 0 auto;
        width: 1280px;

        .hatchleft {
          position: absolute;

          // top: -62px;
          // width: 645px;
          .hatchleftContent {
            width: 800px;
            height: 162px;
            background: linear-gradient(0deg, #0085D0, #09B7CF);
            position: absolute;
            top: -63px;
            left: -471px;

            p:nth-child(1) {
              height: 19px;
              padding: 53px 0 15px 456px;
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 39px;
            }

            p:nth-child(2) {
              width: 118px;
              height: 29px;
              margin-left: 456px;
              font-size: 29px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 39px;
            }
          }
        }

        .hatchNavRight {
          width: 1280px;
          margin: 0 auto;

          // 右边导航
          .hatchNavRightContent {
            flex: 1;
            color: #666666;
            box-sizing: border-box;
            height: 100px;
            background: #FFFFFF;
            border-right: 0px solid #8df;
            box-shadow: 0px 2px 6px 0px rgba(209, 209, 209, 0.65);
            display: flex;
            justify-content: space-around;
            align-items: center;
            position: relative;
            top: -1px;
            left: 329px;
            padding-right: 240px;

            // width: 1280px;

            // margin-right: auto;
            a:hover {
              div {
                color: #0085D0;
              }

              div:nth-child(2) {
              }
            }

            a:nth-child(2) {
              position: relative;

              .bluebottom {
                // display: none;
                position: absolute;
                top: 60px;
                left: -5px;
                width: 72px;
                height: 2px;
                background: #0085D0;
              }
            }

            .companyActive {
              color: #0085D0;

              div:nth-child(2) {
                display: block;
              }
            }

            .companyDefault {
            }
          }
        }
      }

      .contentMain {
        // width: 1280px;
        // margin: 0 auto;
        .companyIntro {
          width: 1280px;
          margin: 0 auto;
          // padding: 119px 319px;
          padding: 119px 0;
          display: flex;

          .IntroLeft {
            padding-right: 24px;

            h4 {
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #333333;
            }

            h4:nth-child(2) {
              margin: 24px 0 60px 0;
              font-size: 26px;
              font-weight: 400;
            }

            p {
              height: 76px;
              font-size: 16px;
              font-weight: 400;
              line-height: 30px;
              color: #333333;
            }

            p:nth-child(3) {
              margin: 60px 0 30px 0;
            }

            p:nth-child(4) {
              margin-bottom: 40px;
            }
          }

          .introRight {
            >img {
              width: 498px;
              height: 514px;
            }
          }
        }

        .croBusiness {
          width: 1280px;
          margin: 0 auto;

          p {
            text-align: center;
            font-size: 30px;
            font-weight: 500;
            color: #333333;
            line-height: 39px;
            margin-bottom: 41px;
          }

          .blueBttom {
            text-align: center;
            margin: 41px auto;
            width: 42px;
            border-bottom: 5px solid #0085D0;
          }
        }

        .bussinessContent {
          width: 1280px;
          margin: 0 auto;
          // padding: 0 319px;
          // padding: 0 0;
          display: flex;
          justify-content: space-between;

          .img {
            background: #FAFAFA;
            position: relative;

            &:hover {
              .defaultImg {
                display: none;
              }

              .activeImg {
                display: block;
              }

              p {
                display: block;
              }
            }

            .activeImg {
              display: none;
            }

            .busyTitle {
              box-sizing: border-box;

              h4 {
                margin: 3px 0 30px 0;
                text-align: center;
                height: 20px;
                font-size: 20px;
                font-weight: bold;
                line-height: 39px;
              }
            }

            p {
              display: none;
              margin-right: 24px;
              position: absolute;
              top: 47px;
              left: 24px;
              color: #fff;
            }
          }
        }

        .industryEntity {
          color: #333333;
          background: #F8F9FA;
          // padding: 140px 319px 150px;
          padding: 140px 0 150px;
          margin-top: 120px;

          .industryEntityContent {
            display: flex;
            justify-content: space-between;
            width: 1280px;
            margin: 0 auto;

            h4 {
              margin: 150px 0 60px 0;
              font-size: 40px;
              font-weight: 500;
            }

            p {
              width: 720px;
              margin: 0 65px 40px 0;
              width: 719px;
              height: 58px;
              font-size: 20px;
              font-weight: 400;
              line-height: 39px;
            }
          }
        }

        .partner {
          width: 1280px;
          margin: 0 auto;
          // padding: 0 319px;
          padding: 0 0;

          .partnerTop {
            h4, p {
              text-align: center;
              height: 19px;
              font-weight: bold;
              line-height: 39px;
            }

            h4 {
              margin-top: 120px;
              font-size: 24px;
            }

            p {
              font-weight: 400;
              margin: 23px 0 60px;
              height: 24px;
              font-size: 26px;
              font-weight: 400;
              line-height: 39px;
            }
          }

          .partnerBottom {
            ul {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              margin-bottom: 120px;

              li {
                width: 216px;
                height: 130px;
                border: 1px solid #ccc;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 60px;

                img {
                  width: 181px;
                  height: 86px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>